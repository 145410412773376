import React from "react";
import {AppBar, Box, Grid, Theme, Toolbar, useMediaQuery} from "@mui/material";

import {HeaderPhone} from "./Header/Phone/Header";
import {HeaderDesktop} from "./Header/Desktop/Header";
import {SubHeaderDesktop} from "./SubHeader/Desktop/SubHeader";
import {OptionsMenu} from "./Menu/Menu";

export function Bar() {
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

    return (
        <Box sx={{marginBottom: isSmall ? '70px': '150px'}}>
            <AppBar elevation={8}>
                <Toolbar>
                    <Grid container alignItems="center">
                        <Grid item xs={isSmall ? 11 : 11.5}>
                            {isSmall ? (
                                <HeaderPhone/>
                            ) : (
                                <>
                                    <HeaderDesktop/>
                                    <SubHeaderDesktop text={["Öffentlich bestellter", "Hammfelddamm 6, 41460 Neuss"]}/>
                                    <SubHeaderDesktop text={["Vermessungsingenieur (Ö.b.V.I)", "Telefon: 02131 - 1709220"]}/>
                                    <SubHeaderDesktop text={["Dipl.-Ing. Andreas Mühlhans", "Telefax: 02131 - 1709226"]} sx={{ paddingBottom: "10px" }}/>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={isSmall ? 1 : 0.5}>
                            <OptionsMenu/>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Box>
    );
}