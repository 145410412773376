import React from "react";
import {Grid, Typography, Zoom} from "@mui/material";

import Protection from "../../styles/images/pawel-czerwinski-6lQDFGOB1iw-unsplash.jpg";


export function DataProtection() {
    document.title = "MÜHLHANS - DATENSCHUTZERKLÄRUNG";
    return (
        <Zoom in>
            <Grid container direction="column" justifyContent="center" textAlign="center" spacing={5}>
                <Grid item>
                    <Typography variant="h4">Datenschutzerklärung</Typography>
                    <Typography variant="body1" color="text.secondary">
                        Lesen Sie wie Ihre Daten auf unserer Seite verarbeitet werden.
                    </Typography>
                </Grid>
                <Grid item>
                    <img className="auto-resize mw-1400" src={Protection} alt="Datenschutzerklärung" loading="lazy"/>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>Allgemeines</Typography>
                    <Typography variant="body1" color="text.secondary">
                        Der Schutz Ihrer Privatsphäre ist für mich sehr wichtig. Die Nutzung der Webseite ist ohne
                        Angabe personenbezogener Daten möglich. Soweit auf meiner Seite personenbezogene Daten
                        (beispielsweise Name, Anschrift, E-Mail-Adressen) doch erhoben werden sollten, erfolgt dies,
                        soweit es mir möglich ist, nur auf freiwilliger Basis (vgl. § 13 TMG). Die sensiblen Daten
                        werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Ich weise darauf hin,
                        dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
                        aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                        Die Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien durch
                        die Benutzung der Kontaktdaten, die im Rahmen der Erfüllung der Impressumspflicht gem. § 5 TMG
                        preisgegeben werden, wird hiermit ausdrücklich widersprochen. Ich behalte mir ausdrücklich
                        rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
                        Spam-Mails (insbesondere Rundmails), vor.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>1. Name und Kontaktdaten des für die Verarbeitung
                        Verantwortlichen</Typography>
                    <Typography variant="body1" color="text.secondary">
                        Diese Datenschutz-Information gilt für die Datenverarbeitung durch:
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Öffentlich bestellter Vermessungsingenieur
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Dipl.-Ing Andreas Mühlhans
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Hammfelddamm 6
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        D-41460 Neuss
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Email: post@vermessung-neuss.de
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Telefon: +49 (0)2131-1709220
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>2. Erhebung und Speicherung personenbezogener Daten sowie Art
                        und Zweck von
                        deren Verwendung</Typography>
                    <Typography variant="body1" color="text.secondary">
                        <b className="gray">A) Beim Besuch der Website</b>
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                        Beim Aufrufen meiner Website werden durch den auf Ihrem Endgerät zum Einsatz
                        kommenden Browser automatisch Informationen an den Server meiner Website gesendet.
                        Diese Informationen werden temporär in einem Logfile gespeichert (beim externen Server-Provider
                        STRATO). Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten
                        Löschung gespeichert:
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        ● IP-Adresse des anfragenden Rechners
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        ● Datum und Uhrzeit des Zugriffs
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        ● Name und URL der abgerufenen Datei
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        ● Website, von der aus der Zugriff erfolgt
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                        ● verwendeter Browser und ggf. das Betriebssystem Ihres Rechners
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                        Die genannten Daten werden bei mir nicht verarbeitet.
                        Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. b und c DSGVO.
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        <b className="gray">B) Bei Nutzung meines Kontaktformulars</b>
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Bei Fragen jeglicher Art biete ich Ihnen die Möglichkeit, mit mir über eine auf der Website
                        angegebene Mailadresse Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse,
                        Name, eventuell Firmenname, Anschrift sowie der
                        Betreff und eine Nachricht erforderlich, damit ich weiß, von wem die Anfrage stammt und um diese
                        beantworten zu können. Die Datenverarbeitung zum Zwecke der Kontaktauf-nahme mit mir erfolgt
                        nach Art. 6 Abs. 1 S. 1 lit. A DSGVO auf Grundlage
                        Ihrer freiwillig erteilten Einwilligung.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>
                        3. Weitergabe von Daten
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden
                        aufgeführten Zwecken findet nicht statt. Ich gebe Ihre persönlichen Daten nur an Dritte weiter,
                        wenn: Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO
                        ausdrückliche Einwilligung dazu erteilt haben, die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f
                        DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und
                        kein Grund zur Annahme besteht, dass Sie ein
                        überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten haben, für den Fall,
                        dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung
                        besteht, sowie dies gesetzlich zulässig und nach Art.
                        6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich
                        ist.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>
                        4. Betroffenenrechte
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        <b className="gray">Sie haben das Recht:</b>
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        ● gemäß Art. 15 DSGVO Auskunft über Ihre von mir verarbeiteten personenbezogenen Daten zu
                        verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der
                        personenbezogenen Daten, die Kategorien von Empfängern,
                        gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das
                        Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder
                        Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft
                        ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer
                        automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen
                        Informationen zu deren Einzelheiten verlangen.
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei
                        mir gespeicherten personenbezogenen Daten zu verlangen.
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        gemäß Art. 17 DSGVO die Löschung Ihrer bei mir gespeicherten personenbezogenen Daten zu
                        verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und
                        Information, zur Erfüllung einer rechtlichen
                        Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
                        Verteidigung von Rechtsansprüchen erforderlich ist.
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                        verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung
                        unrechtmäßig ist, Sie aber deren Löschung ablehnen und ich die
                        Daten nicht mehr benötige, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
                        Rechtsansprüchen benötige oder Sie gemäß
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        ● Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben; gemäß Art. 20 DSGVO Ihre
                        personenbezogenen Daten, die Sie mir bereitgestellt haben, in einem strukturierten, gängigen und
                        maschinenlesbaren Format zu erhalten oder die
                        Übermittlung an einen anderen Verantwortlichen zu verlangen.
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        ● gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber mir zu
                        widerrufen. Dies hat zur Folge, dass ich die Datenverarbeitung, die auf dieser Einwilligung
                        beruhte, für die Zukunft nicht mehr fortführen darf und
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        ● gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie
                        sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder
                        des Orts des mutmaßlichen Verstoßes wenden.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>
                        5. Widerspruchsrecht
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs.
                        1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch
                        gegen die Verarbeitung Ihrer personenbezogenen
                        Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben
                        oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein
                        generelles Widerspruchsrecht, das ohne Angabe einer
                        besonderen Situation von mir umgesetzt wird. Möchten Sie von Ihrem Widerrufs- oder
                        Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an
                        post@vermessung-neuss.de.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>
                        6. Datensicherheit
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Für die Datensicherheit beim Besuch der Webseite trägt der externe Dienstleister STRATO die
                        Verantwortung.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>
                        7. Aktualität und Änderung dieser Datenschutzerklärung
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2018. Durch die
                        Weiterentwicklung meiner Website und Angebote darüber oder aufgrund geänderter gesetzlicher
                        beziehungsweise behördlicher Vorgaben kann es notwendig werden,
                        diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit
                        auf der Website unter Datenschutz von Ihnen abgerufen und ausgedruckt
                        werden.
                    </Typography>
                </Grid>
            </Grid>
        </Zoom>
    );
}