import React from "react";
import {Grid, Typography} from "@mui/material";

import Wappen from "../../../../styles/images/2000px-Wappenzeichen_NRW.png";

export function HeaderDesktop() {
    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item>
                <img src={Wappen} width="40" height="40" alt="Wappenzeichen NRW" loading="lazy"/>
            </Grid>
            <Grid item>
                <Typography variant="h4" gutterBottom>
                    VERMESSUNGSBÜRO MÜHLHANS
                </Typography>
            </Grid>
        </Grid>
    )
}