import React from "react";
import {Grid, Icon, Typography, Zoom} from "@mui/material";

import Call from "../../styles/images/michal-biernat-h0xEUQXzU38-unsplash.jpg";

export function Contact() {
    document.title = "MÜHLHANS - KONTAKT";
    return (
        <Zoom in>
            <Grid container direction="column" justifyContent="center" textAlign="center" spacing={5}>
                <Grid item>
                    <Typography variant="h4">Kontaktieren Sie uns.</Typography>
                    <Typography variant="body1" color="text.secondary">
                        Sie möchten allgemein beraten werden oder haben anderweitige Fragen? Dann rufen Sie uns gerne an
                        oder schreiben Sie uns eine Email. Wir freuen uns von Ihnen zu hören!
                    </Typography>
                </Grid>
                <Grid item>
                    <img className="auto-resize mw-1400" src={Call} alt="Kontakt" loading="lazy"/>
                </Grid>
                <Grid item>
                    <Typography variant="h4">
                        <Icon className="icon">call</Icon>
                        Telefon
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Rufen Sie uns unter <b className="gray">02131 - 1709220</b> an.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4">
                        <Icon className="icon">email</Icon>
                        E-Mail
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Schreiben Sie uns eine Email an <b><a className="gray"
                                                              href="mailto:post@vermessung-neuss.de">post@vermessung-neuss.de</a></b>.
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4">
                        <Icon className="icon">directions_car</Icon>
                        Anfahrt
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        Klicken Sie <b><a className="gray" target="_blank" rel="noopener noreferrer"
                                          href="https://www.google.com/maps/dir//Vermessungsb%C3%BCro+M%C3%BChlhans,+Hammfelddamm+6,+41460+Neuss">hier</a></b>,
                        um zu Google Maps weitergeleitet zu werden.
                    </Typography>
                </Grid>
            </Grid>
        </Zoom>
    );
}