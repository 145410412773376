import {createSlice} from '@reduxjs/toolkit'

export interface MenuSliceState {
    optionsMenu: any;
}

const initialState = {
    optionsMenu: null
} as MenuSliceState;

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setOptionsMenu: (state, action) => {
            state.optionsMenu = action.payload;
        }
    },
})

export const {setOptionsMenu} = menuSlice.actions