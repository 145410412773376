import {configureStore} from '@reduxjs/toolkit'
import {menuSlice} from "./Menu/MenuSlice";

export default configureStore({
    reducer: {
        menu: menuSlice.reducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})