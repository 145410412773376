import React from 'react';
import {Route, Routes} from "react-router-dom";

import {Bar} from "./components/Bar/Bar";
import {Home} from "./pages/Home/Home";
import {Service} from "./pages/Service/Service";
import {Contact} from "./pages/Contact/Contact";
import {Imprint} from "./pages/Imprint/Imprint";
import {DataProtection} from "./pages/DataProtection/DataProtection";


function App() {
    return (
        <>
            <Bar/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/leistungen" element={<Service/>}/>
                <Route path="/kontakt" element={<Contact/>}/>
                <Route path="/impressum" element={<Imprint/>}/>
                <Route path="/datenschutz" element={<DataProtection/>}/>
            </Routes>
        </>
    );
}

export default App;
