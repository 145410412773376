import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Divider, Icon, IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";

import {setOptionsMenu} from "../../../store/Menu/MenuSlice";

export function OptionsMenu() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const optionsMenu = useSelector((state: any) => state.menu.optionsMenu);
    const open = Boolean(optionsMenu);

    const handleClick = (event: any) => {
        dispatch(setOptionsMenu(event.currentTarget));
    };

    const handleClose = (to: string) => {
        dispatch(setOptionsMenu(null));
        navigate(to);
    };

    return (
        <div>
            <IconButton
                id="open-button"
                color="inherit"
                aria-controls={open ? 'options-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                <Icon>menu</Icon>
            </IconButton>
            <Menu
                id="options-menu"
                anchorEl={optionsMenu}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'open-button',
                }}>
                <MenuItem onClick={() => handleClose("/")}>
                    <ListItemIcon>
                        <Icon>info</Icon>
                    </ListItemIcon>
                    <ListItemText>
                        Info
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose("/leistungen")}>
                    <ListItemIcon>
                        <Icon>list</Icon>
                    </ListItemIcon>
                    <ListItemText>
                        Leistungen
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose("kontakt")}>
                    <ListItemIcon>
                        <Icon>contact_page</Icon>
                    </ListItemIcon>
                    <ListItemText>
                        Kontakt
                    </ListItemText>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={() => handleClose("impressum")}>
                    <ListItemIcon>
                        <Icon>bookmark</Icon>
                    </ListItemIcon>
                    <ListItemText>
                        Impressum
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose("/datenschutz")}>
                    <ListItemIcon>
                        <Icon>menu_book</Icon>
                    </ListItemIcon>
                    <ListItemText>Datenschutzerklärung</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
}