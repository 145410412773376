import React from "react";
import {useNavigate} from "react-router-dom";
import {Button, Grid, styled, Theme, Typography, useMediaQuery, Zoom} from "@mui/material";

import Info from "../../styles/images/photo-1503387837-b154d5074bd2.jpg";


export function Home() {
    document.title = "MÜHLHANS";
    const navigate = useNavigate();
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

    const Item = styled(Button)(({theme}) => ({
        backgroundColor: '#A9A9A9',
        ...theme.typography.body2,
        height: theme.spacing(10),
        width: isSmall ? theme.spacing(35) : theme.spacing(70),
        textAlign: 'center',
        color: 'white',
        '&:hover': {
            backgroundColor: '#808080'
        }
    }));

    return (
        <Zoom in>
            <Grid container direction="column" justifyContent="center" textAlign="center" spacing={5}>
                <Grid item>
                    <Typography variant="h4">Übersicht.</Typography>
                    <Typography variant="body1" color="text.secondary">
                        Wählen Sie eine der Kacheln, um an die entsprechende Stelle zu navigieren.
                    </Typography>
                </Grid>
                <Grid item>
                    <img className="auto-resize mw-1400" src={Info} alt="Info" loading="lazy"/>
                </Grid>
                <Grid item>
                    <Grid container direction={isSmall ? "column" : "row"} justifyContent="center" textAlign="center" spacing={isSmall ? 8 : 16}>
                        <Grid item>
                            <Item onClick={() => navigate('/leistungen')}>Leistungen</Item>
                        </Grid>
                        <Grid item>
                            <Item onClick={() => navigate('/kontakt')}>Kontakt</Item>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Zoom>
    )
}