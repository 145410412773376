import React from "react";
import {Grid, Typography} from "@mui/material";

export interface SubHeaderDesktopProps {
    text: string[];
    sx?: {};
}

export function SubHeaderDesktop({text, sx}: SubHeaderDesktopProps) {
    return (
        <Grid container sx={sx ? sx : undefined}>
            {text.map(text => (
                <Grid key={text} item xs={2.5}>
                    <Typography>{text}</Typography>
                </Grid>
            ))}
        </Grid>
    );
}