import React from "react";
import {Grid, Paper, Stack, styled, Theme, Typography, useMediaQuery, Zoom} from "@mui/material";

import Services from "../../styles/images/sven-mieke-fteR0e2BzKo-unsplash.jpg";

export function Service() {
    document.title = "MÜHLHANS - LEISTUNGEN";
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

    const Item = styled(Paper)(({theme}) => ({
        backgroundColor: 'white',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        width: isSmall ? theme.spacing(35) : undefined,
        textAlign: 'center',
        color: theme.palette.text.secondary
    }));

    return (
        <Zoom in>
            <Grid container direction="column" justifyContent="center" textAlign="center" spacing={5}>
                <Grid item>
                    <Typography variant="h4">Was wir Ihnen bieten.</Typography>
                    <Typography variant="body1" color="text.secondary">
                        Neben professioneller Beratung bieten wir Ihnen eine Vielzahl an Leistungen
                        an.
                    </Typography>
                </Grid>
                <Grid item>
                    <img className="auto-resize mw-1400" src={Services} alt="Leistungen" loading="lazy"/>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>Katastervermessung (Hoheitliche Leistung)</Typography>
                    <Stack direction={isSmall ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1}>
                        <Item>Teilungsvermessung</Item>
                        <Item>Gebäudeeinmessung</Item>
                        <Item>Amtlicher Lageplan</Item>
                    </Stack>
                    <Stack direction={isSmall ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1}>
                        <Item>Baulastplan</Item>
                        <Item>Grenzwiederherstellung</Item>
                        <Item>Grenzfeststellung</Item>
                    </Stack>
                    <Stack direction={isSmall ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1}>
                        <Item>Grenzgutachten</Item>
                        <Item>Grenzanzeige</Item>
                        <Item>Straßenschlussvermessung</Item>
                    </Stack>
                    <Stack direction={isSmall ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1}>
                        <Item>Umlegungsverfahren</Item>
                        <Item>Grenz- und Sockelabnahmebescheinigung</Item>
                    </Stack>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>Ingenieurvermessung (Nicht hoheitliche Leistung)</Typography>
                    <Stack direction={isSmall ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1}>
                        <Item>nicht Amtlicher Lageplan</Item>
                        <Item>Absteckungsarbeiten (Grob- und Feinabsteckung)</Item>
                        <Item>Bebauungsplan sowie Bebauungsplangrundlage</Item>
                    </Stack>
                    <Stack direction={isSmall ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1}>
                        <Item>Planungsgrundlage/Bestandsplan</Item>
                        <Item>Höhenmessung</Item>
                        <Item>Leitnivellement</Item>
                    </Stack>
                    <Stack direction={isSmall ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1}>
                        <Item>Kanalvermessung</Item>
                    </Stack>
                </Grid>
                <Grid item>
                    <Typography variant="h4" gutterBottom>Weitere Leistungen</Typography>
                    <Stack direction={isSmall ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1}>
                        <Item>Wertgutachten (Immobilien, Grundstücke)</Item>
                        <Item>Gerichtsgutachten</Item>
                        <Item>Wohnflächenermittlung</Item>
                    </Stack>
                    <Stack direction={isSmall ? "column" : "row"} justifyContent="center" alignItems="center" spacing={1}>
                        <Item>Kataster- und Grundstücksberatung</Item>
                        <Item>und noch vieles mehr</Item>
                    </Stack>
                </Grid>
            </Grid>
        </Zoom>
    );
}