import React from "react";
import {Grid, Typography} from "@mui/material";

import Wappen from "../../../../styles/images/2000px-Wappenzeichen_NRW.png";

export function HeaderPhone() {
    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item>
                <img src={Wappen} width="20" height="20" alt="Wappenzeichen NRW" loading="lazy"/>
            </Grid>
            <Grid item>
                <Typography>
                    VERMESSUNGSBÜRO
                </Typography>
                <Typography>
                    MÜHLHANS
                </Typography>
            </Grid>
        </Grid>
    )
}